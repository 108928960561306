.check_list_item {
    
    padding: 10px;
    input {
        display: none;
        
    }
    input:checked {
        ~.one_item .box,
        ~.one_item .name {
            border: 3px solid rgb(179, 1, 1);
        }
        ~.one_item .box {
            background: center center no-repeat url(https://api.totoopen.by/img/Check.png);
        }
    }
    
    .one_item {
        display: flex;
        align-self: center;
        .name {
            border-radius: 14px;
            background-color: white;
            box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
            text-align: center;
            font-size: 22px;
            font-weight: bold;
            width: 100%;
            height: 60px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .box {
            height: 60px;
            width: 60px;
            margin-left: 10px;
            border-radius: 14px;
            background-color: white;
            box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
            flex-shrink: 0;
            box-sizing: border-box;
        }
    }
}
